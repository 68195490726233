import React from 'react';
import './ourServicesSection.css';
import { ourServicesData } from '../../data/ourservices';

export default function OurServicesSection() {
  return (
    <div className="ourservices-section margin-auto" id="services">
      <div className="wrapper margin-auto">
        <h1 className="primary-font stroke-outlined margin-center">
          {ourServicesData.Title}
        </h1>
        <div className="ourservices-section-contents">
          {ourServicesData.Members.map((item) => (
            <div className="ourservices-section-contents-item flex">
              <img src={item.img}></img>
              <div className="ourservices-section-contents-item-right">
                <h1 className="secondary-font">{item.title}</h1>
                <p className="secondary-font">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
