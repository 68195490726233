import React from 'react';
import './footer.css';
import ContactForm from '../ContactForm/ContactForm';
import ContactInfo from '../ContactInfo/ContactInfo';
import SocialLinks from '../SocialLinks/SocialLinks';
import Copyright from '../Copyright/Copyright';

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-content-wrapper margin-auto flex">
        <ContactForm />
        <div className="footer-right-section flex">
          <ContactInfo className="contact-info-footer" />
          <SocialLinks />
        </div>
      </div>
      <Copyright />
    </div>
  );
}
