import React from 'react';
import './featuredSection.css';
import { featuredData } from '../../data/featured';

export default function FeaturedSection() {
  return (
    <div className="featured-section" id="featured">
      <div className="featured-section-wrapper margin-auto">
        <img
          src={featuredData.Title}
          className="featured-title margin-center"
        ></img>
        <div className="featured-contents margin-center">
          {featuredData.featuredContent.map((item) => (
            <div className="featured-contents-item">
              <img src={item.img} className="margin-center"></img>
              <h2 className="primary-font">{item.Title}</h2>
              <p className="secondary-font">{item.Description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
