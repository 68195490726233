import React from 'react';
import { whyChooseData } from '../../data/whyChooseUs';
import './whyChooseUsSection.css';
export default function WhyChooseUsSection() {
  return (
    <div className="why-choose-us-section">
      <div className="why-choose-us-section-wrapper margin-auto">
        <h1 className="why-choose-us-section-title primary-font">
          {whyChooseData.Title}
        </h1>
        <img src={whyChooseData.Img} className="brain-storm"></img>
        <div className="why-choose-us-section-contents flex margin-auto">
          {whyChooseData.whyChooseContent.map((item) => (
            <div className="why-choose-us-section-contents-item flex">
              <img src={item.img}></img>
              <h3 className="secondary-font">{item.Description}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
