const featuredData = {
  Title: 'assets/featured.svg',
  featuredContent: [
    {
      img: './assets/featured/featuredWebdev.svg',
      Title: 'Web Apps',
      Description:
        'Building and hosting high performance web aplications that scales with demand.'
    },
    {
      img: './assets/featured/featuredTracking.svg',
      Title: 'Transport Tracking',
      Description:
        'Our Transport Tracking System gives you the real-time location and details of your vehicle.'
    },
    {
      img: './assets/featured/featuredHRM.svg',
      Title: 'HRM',
      Description:
        'Our Human Resource Management System provides the industry standard tools to help your business.'
    },
    {
      img: './assets/featured/featuredISM.svg',
      Title: 'LMS',
      Description:
        'Our Learning Management System includes all the necessary tools for an online learning platform.'
    }
  ]
};
export { featuredData };
