const socialData = {
  Title: 'Find Us On',
  Members: [
    {
      img: './assets/socialicons/facebook.svg',
      title: 'Mangrove software & it',
      description: '@Handle'
    },
    {
      img: './assets/socialicons/instagram.svg',
      title: 'Mangrove software & it',
      description: '@Handle'
    },
    {
      img: './assets/socialicons/youtube.svg',
      title: 'Mangrove software & it',
      description: '@Handle'
    },
    {
      img: './assets/socialicons/twitter.svg',
      title: 'Mangrove software & it',
      description: '@Handle'
    }
  ]
};
export { socialData };
