import React from 'react';
import { heroSectionData } from '../../data/heroSection';
import './heroSection.css';

export default function HeroSection() {
  return (
    <div className="hero-section margin-auto">
      <div className="hero-section-wrapper flex margin-auto">
        <div className="HeroSectionBg"></div>
        <div className="hero-top-section flex">
          <img src={heroSectionData.BrandFirstName}></img>
          <img src={heroSectionData.BrandLastName}></img>
          <h2 className="primary-font">
            {heroSectionData.HeroSectionSubHeader}
          </h2>
          <a href="#services">
            <button className="btn primary-font">
              {heroSectionData.ButtonInnerText}
            </button>
          </a>
        </div>
        <div className="hero-bottom-section">
          <img src="assets/hero-illustration.svg"></img>
        </div>
      </div>
    </div>
  );
}
