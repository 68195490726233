import React from 'react';
import AboutSection from '../../Sections/AboutSection/AboutSection';
import FeaturedSection from '../../Sections/FeaturedSection/FeaturedSection';
import HeroSection from '../../Sections/HeroSection/HeroSection';
import MeetTeamSection from '../../Sections/MeetTeamSection/MeetTeamSection';
import OurServicesSection from '../../Sections/OurServicesSection/OurServicesSection';
import WhyChooseUsSection from '../../Sections/WhyChooseUsSection/WhyChooseUsSection';
import './homeScreen.css';
export default function HomeScreen() {
  return (
    <div className="HomeScreen">
      <HeroSection />
      <FeaturedSection />
      <AboutSection />
      <MeetTeamSection />
      <WhyChooseUsSection />
      <OurServicesSection />
    </div>
  );
}
