const teamData = {
  Title: './assets/team/meet-the-team.svg',
  Members: [
    {
      img: './assets/team/dp.png',
      Name: 'Rayhan Islam Shuvo',
      Position: 'Chief Executive Officer'
    },
    {
      img: './assets/team/dp.png',
      Name: 'Ishraf Hossain Bhuiyan',
      Position: 'Managing Director'
    },
    {
      img: './assets/team/dp.png',
      Name: 'Nafis Faisal Khan',
      Position: 'Chief Technology Officer'
    }
  ]
};
export { teamData };
