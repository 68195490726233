const contactData = {
  Title: 'Contact Info',
  Members: [
    {
      img: './assets/contact/location.svg',
      title: 'Address',
      description: 'Dhaka, Bangladesh'
    },
    {
      img: './assets/contact/phone.svg',
      title: 'Phone',
      description: '+8801780453211'
    },
    {
      img: './assets/contact/mail.svg',
      title: 'Email',
      description: 'info@mangroveitsoftware.com'
    }
  ]
};
export { contactData };
