const whyChooseData = {
  Title: 'Why Choose Us ?',
  Img: './assets/whychooseus/brain-storm.svg',
  whyChooseContent: [
    {
      img: './assets/whychooseus/tick-icon.svg',
      Description: 'On-Time Delivery'
    },
    {
      img: './assets/whychooseus/tick-icon.svg',
      Description: 'On-Demand Scaling'
    },
    {
      img: './assets/whychooseus/tick-icon.svg',
      Description: 'Industry Standard Technologies'
    },
    { img: './assets/whychooseus/tick-icon.svg', Description: 'Maintenance' },
    {
      img: './assets/whychooseus/tick-icon.svg',
      Description: 'High Performance Applications'
    }
  ]
};
export { whyChooseData };
