const ourServicesData = {
  Title: 'Our Services',
  Members: [
    {
      img: './assets/services/project-management.svg',
      title: 'Project Management',
      description:
        'Our utitliy provides management of Project resources, Project risk management, Financial management, change and Configuration management of the project.'
    },
    {
      img: './assets/services/quality-assurance.svg',
      title: 'Quality Assurance & Management',
      description:
        'We strive to achieve top of the line Quality control and evaluation of the Software Development Cycle.'
    },
    {
      img: './assets/services/is-studies.svg',
      title: 'IS Studies',
      description:
        'Our services provides Technical studies, Technical evaluations, Implementation/Deployment studies and hosting of Information systems.'
    }
  ]
};
export { ourServicesData };
