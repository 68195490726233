import React from 'react';
import './copyright.css';

export default function Copyright() {
  const year = new Date().getFullYear();
  console.log(year);
  return (
    <div className="copyright-section">
      <h3 className="primary-font margin-center">
        &copy;<span></span> {year} <span></span>Mangrove Software & IT
      </h3>
    </div>
  );
}
