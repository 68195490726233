import React from 'react';
import { aboutData } from '../../data/about';
import './aboutSection.css';

export default function AboutSection() {
  return (
    <div className="about-section-wrapper margin-auto" id="about">
      <img src={aboutData.Title} className="about-title margin-center"></img>
      <div className="about-section-contents flex margin-auto">
        <p className="secondary-font">{aboutData.description}</p>
        <img src={aboutData.img}></img>
      </div>
    </div>
  );
}
