import React from 'react';
import './contactInfo.css';
import { contactData } from '../../data/contactinfo';

export default function ContactInfo() {
  return (
    <div className="contact-info-section">
      <h1 className="primary-font stroke-outlined margin-center">
        {contactData.Title}
      </h1>
      <div className="contact-info-wrapper margin-auto">
        {contactData.Members.map((item) => (
          <div className="contact-info-item flex">
            <img src={item.img}></img>
            <div className="contact-info-item-right">
              <h2 className="secondary-font">{item.title}</h2>
              <p className="secondary-font">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
