import './App.css';
import Footer from './Components/Footer/Footer';
import Nav from './Components/Nav/Nav';
import HomeScreen from './Screens/HomeScreen/HomeScreen';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
function App() {
  return (
    <Router>
      <Nav />
      <Switch>
        <Route path="/" component={HomeScreen} exact />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
