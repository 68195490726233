const menuData = [
  {
    title: 'Features',
    ref: '#featured'
  },
  {
    title: 'About',
    ref: '#about'
  },
  {
    title: 'Services',
    ref: '#services'
  },
  {
    title: 'Team',
    ref: '#team'
  },
  {
    title: 'Contact',
    ref: '#contact'
  }
];

export { menuData };
