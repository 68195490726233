import React, { useState, useEffect } from 'react';
import './nav.css';
import { menuData } from '../../data/menuitem';
import { Link } from 'react-router-dom';
export default function Nav() {
  const [mobile, setMobile] = useState(true);
  const [scrolled, setScrolled] = useState(false);
  const [burger, setBurgerClicked] = useState(false);

  window.onscroll = () => {
    window.pageYOffset === 0 ? setScrolled(false) : setScrolled(true);
  };

  window.onload = () => {
    if (window.innerWidth < 800) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    window.addEventListener('resize', handleResize);
  }, [mobile]);

  return (
    <div
      className={
        scrolled || burger
          ? 'Navbar flex margin-center'
          : 'Navbar-transparent flex margin-center'
      }
    >
      <a href="#">
        <img
          src="/assets/brand-logo-white.svg"
          className="Logo"
          onClick={() => setBurgerClicked(false)}
        ></img>
      </a>

      {!mobile && (
        <div className="navbar-menu-items flex">
          {menuData.map((item) => (
            <div className="menu-item">
              <a href={item.ref}>
                <h1 className="primary-font">{item.title}</h1>
                <div className="underline"></div>
              </a>
            </div>
          ))}
        </div>
      )}
      {mobile && (
        <div
          className={burger ? 'burger-active' : 'Burger'}
          onClick={() => setBurgerClicked((prev) => !prev)}
        >
          <div className="burgerLine"></div>
          <div className="burgerLine"></div>
          <div className="burgerLine"></div>
        </div>
      )}
      {mobile && burger && (
        <div
          className={
            burger
              ? 'navbar-menu-items-mobile flex'
              : 'navbar-menu-items-mobile-hide'
          }
        >
          <div className="navbar-wrapper">
            {menuData.map((item) => (
              <div className="menu-item">
                <a href={item.ref}>
                  <h1
                    className="primary-font"
                    onClick={() => setBurgerClicked(false)}
                  >
                    {item.title}
                  </h1>
                  <div className="underline"></div>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
