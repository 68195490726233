import React from 'react';
import './contactForm.css';
import emailjs from "emailjs-com";

export default function ContactForm() {
  const handleSubmit = (e) => {
    e.preventDefault();

    var formData = new FormData();
    var creds = [];
    for (var i = 0; i < e.target.length; i++) {
      var item = e.target[i];
      if (item.name) {
        // console.log(item.name, item.value);
        formData.append(item.name, item.value);
        creds.push({ [item.name]: item.value });
      }
    }
    console.log(creds);

    emailjs
      .sendForm(
        "service_zka6f2i",
        "template_fjjwv9q",
        e.target,
        "user_JEcBfRw8b5PSafUosfjgV"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Your message has been sent! Thank you for contacting us!");
        },
        (error) => {
          console.log(error.text);
          alert("There was an error! Please try again later!");
        }
      );
  };
  return (
    <div className="contact-form-section" id="contact">
      <h1 className="primary-font stroke-outlined form-header">Get In Touch</h1>
      <p className="secondary-font  form-subheader">
        Please fill out the form below to send us an email and we will get back
        to you as soon as possible.
      </p>
      <form className="contact-form flex"  onSubmit={handleSubmit} noValidate>
        <div className="form-control">
          <input
            name="name"
            className="name-input secondary-font"
            type="text"
            required
          ></input>
          <label className="name-label secondary-font">Name</label>
        </div>
        <div className="form-control">
          <input
           name="email"
           type="email"
            className="email-input secondary-font"
            type="email"
            required
          ></input>
          <label className="email-label secondary-font">Email</label>
        </div>
        <div className="form-control">
          <input
            name="message"
            id="message"
            className="secondary-font message-input"
            type="text"
            required
          ></input>
          <label className="message-label secondary-font">Message</label>
        </div>
        <button className="btn primary-font">Send Message</button>
      </form>
    </div>
  );
}
