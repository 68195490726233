import React from 'react';
import { teamData } from '../../data/meetTeam';
import './meetTeamSection.css';

export default function MeetTeamSection() {
  return (
    <div className="meet-team-section-wrapper margin-auto" id="team">
      <img src={teamData.Title} className="meet-team-title margin-center"></img>
      <div className="meet-team-contents flex margin-center">
        {teamData.Members.map((item) => (
          <div className="meet-team-contents-item flex">
            <img src={item.img}></img>
            <div className="meet-team-contents-item-left ">
              <h1 className="secondary-font">{item.Name}</h1>
              <h4 className="secondary-font">{item.Position}</h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
