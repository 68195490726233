import React from 'react';
import './socialLinks.css';
import { socialData } from '../../data/social';

export default function SocialLinks() {
  return (
    <div className="social-links-section margin-auto">
      <h1 className="primary-font stroke-outlined">{socialData.Title}</h1>
      <div className="social-links-section-wrapper flex margin-auto">
        {socialData.Members.map((item) => (
          <div className="social-links-section-content-item margin-auto">
            <img src={item.img}></img>
            <h2 className="secondary-font">{item.title}</h2>
            <p className="secondary-font">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
